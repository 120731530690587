import React, { useState, useEffect, useRef, useContext, useCallback, useLayoutEffect } from 'react';
import { Button, Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Popup from 'reactjs-popup';
import { ConfigContext } from '../../components/Common/ConfigHelper';
import { GlobalContext } from '../../contexts/GlobalContext';
import OtpInput from 'react-otp-input';
import { getDeviceType } from '../Common/CommonHooks';
import account_opening_qrcode_1 from '../../info/Include/images/account_opening_qrcode_1.svg'
import account_opening_logo from '../../info/Include/images/account_opening_logo.png'
import step1 from '../../info/Include/images/otp_update_mobile_step1.svg'
import step2 from '../../info/Include/images/otp_update_mobile_step2.svg'
import step1ch from '../../info/Include/images/otp_update_mobile_step1_ch.svg'
import step2ch from '../../info/Include/images/otp_update_mobile_step2_ch.svg'
import QRCode from "react-qr-code"

import {Toast} from '../Common/ui-components';
import { WATrackerTrackClickEvent } from '../Common/utils/wa';

export const LoginOTP = ({
    showMsg = false,
    onClickNext,
    invalidOTP,
    isMobile,
    otp,
    setOtp,
    setIsShowOTPAuth,
    onClickEKBA,
    onClickCancelOTP,
    closeBtnEnabled = false,
    getMobile,
    resendOTP,
    isShowOTPToast,
    setIsShowOTPToast,
    setShowLoginLockPopup,
    isMissingMobile
}) => {
    const { t, i18n } = useTranslation();
    const lang = i18n.language;
    const { betslipMenu } = useContext(ConfigContext);
    const globalContext = useContext(GlobalContext);
    const [menuInfo, setMenuInfo] = useState(betslipMenu);
    const numericOnly = (event) => {
        if (!/[0-9]/.test(event.key)) {
          event.preventDefault();
        }
    }
    const resendOtpInterval = window.globalConfig.resendOtpInterval;
    const OTP_RAO_CONFIG = window.globalConfig.OTP_RAO_CONFIG;

    // const [otp, setOtp] = useState('');
    const [resent, setResent] = useState(false);
    const [resendTimer, setResendTimer] = useState(resendOtpInterval);
    const [clearOTP, setClearOTP] = useState(true);
    const [accountOpeningURL, setAccountOpeningURL] = useState('#');
    const [isShowAccountOpeningQRCode, setIsShowAccountOpeningQRCode] = useState(false);
    const [isShowTroublePopup, setIsShowTroublePopup] = useState(false);
    const [toastApi, toastContent] = Toast.useToast({ max: 1,showTime:3000,isOTP:true});
    let mobileStyle = isMobile ? 'is-mobile' : 'is-desktop';
    useEffect(() => {
        if (isMobile) {
            mobileStyle = 'is-mobile'
        } else {
            mobileStyle = 'is-desktop';
        }
    }, [isMobile]);

    useEffect(() => {
        console.log('init LoginOTP', getMobile().timeout)
        setResendTimer(getMobile().timeout)
        setResent(true)
    }, []);

    useEffect(() => {
        let interval;
        if (resent) {
          interval = setInterval(() => {
            setResendTimer((prevTimer) => prevTimer - 1);
          }, 1000);
        }
    
        return () => clearInterval(interval);
    }, [resent]);

    useEffect(() => {
    if (resendTimer === 0) {
        setResent(false);
        setResendTimer(resendOtpInterval);
    }
    }, [resendTimer]);
    useEffect(()=>{
        if(isShowOTPToast==true){
            showToast();
        }
        let close = setTimeout(() => {
            setIsShowOTPToast(false);
        }, 3000);
        return ()=>{
            clearTimeout(close)
        };
    },[isShowOTPToast])
    const handleResendClick = () => {
        resendOTP();
        setResent(true);
    };

    const formatResendTimer = () => {
        const minutes = Math.floor(resendTimer / 60);
        const seconds = resendTimer % 60;
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    const clearOTPInput = () => {
        if (!invalidOTP || !clearOTP) {
            console.log('invalidOTP, clearOTP', invalidOTP, clearOTP)
            return;
        }
        setOtp('');
        setClearOTP(false);
    }

    useEffect(() => {
        if(invalidOTP.length>0){
            if(invalidOTP.includes("ERR"))
                invalidOTP = invalidOTP.replace("ERR",'')
        }
        setClearOTP(true);
        if(invalidOTP=='100014'){
          invalidOTP=""
          setShowLoginLockPopup(true)
          onClickCancelOTP()
        }
    }, [invalidOTP])
    const showToast =()=>{
        toastApi({ message:  t('LB_OTP_RESEND_SUCCESS')});
    }
    useLayoutEffect(() => {
        if (otp.length >= 6) {
          // Manually unfocus the input box
          console.log('unfocus otp input')
          document.activeElement.blur();
          // check otp
          onClickNext();
        }
      }, [otp]);

    const handleClickAccountOpening = () => {
        const device = getDeviceType();
        // if (device.isTablet && !device.isDesktop ) { // tablet -> QR code
        //     setIsShowAccountOpeningQRCode(true);
        // }
        
        setIsShowAccountOpeningQRCode(true); // testing
        WATrackerTrackClickEvent("eWin_CIAM_S1a-OTP-clickChangeMobile_whitelistWithMobile");
    };

    useEffect(() => {
        const device = getDeviceType();

        if (device.isTablet) {
            setAccountOpeningURL('#');
        } else if (device.isDesktop) {
            setAccountOpeningURL(t('LB_OTP_ACCOUNT_OPENING_MHKJC'));
        } else {
            setAccountOpeningURL(t('LB_OTP_ACCOUNT_OPENING_SH'));
        }
        // setAccountOpeningURL('#'); // testing
    }, [navigator.platform, navigator.userAgent]);
    const handleRedirectToRAO=()=>{
        window.open(lang=='ch'?OTP_RAO_CONFIG.linkCH:OTP_RAO_CONFIG.linkEN);
    }
    const handleAccountOpeningCancelBtn = () => {
        setIsShowAccountOpeningQRCode(false);
        onClickCancelOTP();
    }

    return (
        <>
      
        <Popup open={showMsg} closeOnDocumentClick={false} className={`comingSoonLogOutDialog logout-succ-msg otp-popup otp-popup-content  ${invalidOTP ? 'otp-invalid' : ''} ${mobileStyle}`}>
            {!isShowTroublePopup?
            <div className={`otp-popup-container ${invalidOTP ? 'otp-invalid' : ''}`}>
                {toastContent}
                <div className="otp-title" id="AuthTitle">{t('LB_OTP_0')}</div>
                <div className="otp-1" id="Auth_content_prompt" dangerouslySetInnerHTML={{ __html: t('LB_OTP_1') + `<b>${getMobile()?.mobileNo}</b>`}} ></div>
                <div className="otp-pleaseinput" id="code_label">{t('LB_OTP_5')}</div>
                <div id="code_input_box">
                    <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        renderInput={(props) => <input onClick={clearOTPInput} onContextMenu={(e) => e.preventDefault()} {...props} />}
                        containerStyle="d-flex mt-2 gap-2"
                        inputStyle={`otp-input ${!invalidOTP || !clearOTP ? '' : 'otp-input-invalid'}`}
                        shouldAutoFocus={true}
                        inputType={'number'}
                    />
                </div>
                {invalidOTP && <div className='otp-invalid-code' id="code_tips">{t('LB_OTP_ERR'+invalidOTP)}</div>}
                
                <div className="otp-didnotreceive" id="code_resend_ask">{t('LB_OTP_6')}</div>
                <div 
                    className={`otpauth-button ${resent ? 'resent' : ''}`}
                    onClick={resent ? undefined : handleResendClick}
                    id="code_resend"
                >
                    <span>{t('LB_OTP_7')}</span>
                    {resent && <span> ({formatResendTimer()})</span>}
                </div>
                

                <div className="otpauth-trouble" onClick={()=> {
                        setIsShowTroublePopup(true);
                        WATrackerTrackClickEvent("eWin_CIAM_S1a-OTP-clickTrouble_whitelistWithMobile");
                    }}>{t('LB_OTP_TROUBLE')}
                </div>
                {/* <div className="otpauth-change-mobile mt-4">{t('LB_OTP_CHANGE_MOBILE')}</div>
                <div className="otpauth-button"><a href={accountOpeningURL} target={accountOpeningURL === '#' ? '' : "_blank"} onClick={handleClickAccountOpening}>{t('LB_OTP_CHANGE_MOBILE_BTN')}</a></div>
                <div className="otpauth-change-mobile-msg" id="Auth_content_notice">{t('LB_OTP_CHANGE_MOBILE_MSG')}</div>

                <div className="otpauth-change-mobile mt-4" id="Verify_loginAnswer">{t('LB_OTP_VERIFY_BY_EKBA')}</div>
                <div className="otpauth-button" onClick={onClickEKBA}>{t('LB_OTP_VERIFY_BY_EKBA_BTN')}</div> */}

                <div className="otpauth-button otpauth-cancel mt-3" onClick={onClickCancelOTP} id="Cancel">{t('LB_OTP_CANCEL')}</div>

            </div>
            :
           
                <div className='otp-trouble'>
                    <div className='otp-acopening-title'>{t('LB_OTP_TROUBLE')}</div>
                    <div className='otp-trouble-container'> 
                        <div className='otp-trouble-message' onClick={handleClickAccountOpening}>{t('LB_OTP_TROUBLE_OPT1')}</div>
                        <div className='otp-trouble-message' onClick={onClickEKBA} >{t('LB_OTP_TROUBLE_OPT2')}</div>
                    </div>
                    <div className='otp-trouble-group'>
                        <div className='cancel-btn' onClick={()=>{
                                setIsShowTroublePopup(false);
                            }}>{t('LB_OTP_BACK_TO_OTP')}
                        </div>
                    </div>
                </div>

            }
            <Popup open={isShowAccountOpeningQRCode} closeOnDocumentClick={false} onClose={() => setIsShowAccountOpeningQRCode(false)} className={`comingSoonLogOutDialog otp-acopening ${mobileStyle}`}>
                <div className='otp-acopening'>
                    {closeBtnEnabled && (
                        <div className="otp-acopening-close" onClick={handleAccountOpeningCancelBtn}>
                            &times;
                        </div>
                    )}
                    <div className='otp-acopening-title'>{!isMissingMobile?t('LB_OTP_ACCOUNT_OPENING_TITLE'):t('LB_OTP_ACCOUNT_MISSING_MOBILE')}</div>
                    <div className='otp-acopening-message'></div>
                    <div className='otp-acopening-img-group'>
                        <img src={account_opening_logo} alt="account opening logo" className='otp-acopening-logo'/>
                        <div className='otp-acopening-app-name'>{t('LB_OTP_ACCOUNT_OPENING_APP_NAME')}       
                            <div className='otp-acopening-app-hints'>{t('LB_OTP_ACCOUNT_UPDATE_MOBILE_HINTS')}</div>
                        </div>
                  
                        {/* <img src={account_opening_qrcode_1} alt="account opening qrcode" className='otp-acopening-qrcode'/> */}
                        {!isMobile&&<div className='otp-acopening-qrcode-div'>
                            <QRCode
                                value={lang=='en'?OTP_RAO_CONFIG.linkEN:OTP_RAO_CONFIG.linkCH}
                                fgColor='#003C84'
                            />
                            <div className='otp-acopening-qrcode-label'>
                                <span>{t('LB_OTP_ACCOUNT_OPENING_QRCODE_LABEL')}</span>
                            </div>
                        </div>}
                    </div>
                    <div className='otp-acopening-message'>{t('LB_OTP_ACCOUNT_OPENING_MESSAGE_3')}</div>
                       
                        <div className='otp-acopening-message-container'>
                            
                            <div>
                                <img src={lang=='en'?step1:step1ch} alt="account opening logo" className='otp-acopening-step-image'/>
                                <div className='otp-acopening-step'> 
                                    <div className='otp-acopening-step-index'>1</div> 
                                    <div>{t('LB_OTP_ACCOUNT_UPDATE_MOBILE_STEP1')}</div> 
                                </div>
                            </div>
                            <div>
                                <img src={lang=='en'?step2:step2ch} alt="account opening logo" className='otp-acopening-step-image'/>
                                <div className='otp-acopening-step'> 
                                    <div className='otp-acopening-step-index'>2</div> 
                                    <div>{t('LB_OTP_ACCOUNT_UPDATE_MOBILE_STEP1')}</div> 
                                </div>
                            </div>
                            
                        </div>
              
                    {!isMobile?
                        <div className='otp-acopening-btn-group'>
                            <div className='row1'>
                                <div className='cta_blue' onClick={() => handleAccountOpeningCancelBtn()}>{t('LB_CLOSE')}</div>
                            </div>
                            {/* <div className='row2'>
                                <div className='cancel-btn' onClick={handleAccountOpeningCancelBtn}>{t('LB_BS_LOGIN_CANCEL')}</div>
                            </div> */}
                        </div>
                    :
                        <div className='otp-acopening-btn-group'>
                            <div className='row1'>
                                <div className='cta_yellow' onClick={() => handleRedirectToRAO()}>{t('LB_OPEN')}</div>
                            </div>
                            <div className='row2'>
                                <div className='cancel-btn' onClick={() => handleAccountOpeningCancelBtn()}>{t('LB_BS_LOGIN_CANCEL')}</div>
                            </div>
                        </div>
                    }
                </div>
            </Popup>
        </Popup>
        </>
    );
};