import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Popup from 'reactjs-popup';
import { useWindowSize } from '../Common/CommonHooks';

const ErrorPopup = ({errorType, handleClickCancel, setShowErrorPopup}) => {
    const { t, i18n } = useTranslation();

    const { isMobileSize } = useWindowSize();
    let mobileStyle = isMobileSize ? 'is-mobile' : 'is-desktop';
    useEffect(() => {
        if (isMobileSize) {
            mobileStyle = 'is-mobile'
        } else {
            mobileStyle = 'is-desktop';
        }
    }, [isMobileSize]);

    let errorTitle = t('LS_BS_ERRORPOPUP_BUSY_TITLE');
    let errorMessage = t('LS_BS_ERRORPOPUP_BUSY_MESSAGE');
    if (errorType === 'session timeout') {
        errorTitle = t('LS_BS_ERRORPOPUP_TIMEOUT_TITLE');
        errorMessage = t('LS_BS_ERRORPOPUP_TIMEOUT_MESSAGE');
    } else if(errorType==='network'){
        errorTitle = t('LS_BS_ERRORPOPUP_BUSY_TITLE');
        errorMessage = t('LS_BS_ERRORPOPUP_BUSY_MESSAGE');
    } else if (errorType === 'exception') {
        errorTitle = t('LS_BS_ERRORPOPUP_BUSY_TITLE');
        errorMessage = t('LS_BS_LOGIN_ERROR_CIAM_ERROR');
    } else if (errorType === "100003") {
        errorTitle = t('LS_BS_ERRORPOPUP_BUSY_TITLE');
        errorMessage = t('LB_BS_LOGIN_ERROR_CIAM_100003');
    } else {
        errorTitle = t('LS_BS_ERRORPOPUP_BUSY_TITLE');
        errorMessage = t('LS_BS_LOGIN_ERROR_CIAM_ERROR');
    }

    const handleClickClose = () => {
        // only close the popup if error occurs when trusting browser
        if (errorType === 'trust browser failure') {
            setShowErrorPopup(false);
        }
        handleClickCancel();
    }
    
    return (
        <Popup open={true} closeOnDocumentClick={false} className={`errorpopup ${mobileStyle}`} onClose={handleClickClose}>
            <div className="errorpopup-container">
                <div className="errorpopup-title">{errorTitle}</div>
                <div className="errorpopup-message">{errorMessage}</div>
                <div className={`button-box errorpopup-btn-grp`}>
                    <div className='row2'>
                        <div className="errorpopup-close" onClick={handleClickClose}>{t('LB_CLOSE')}</div>
                    </div>
                </div>
            </div>
        </Popup>
    )
}

export default ErrorPopup;