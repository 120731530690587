import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import { MarksixContext } from '../../Home/MarksixPage';
import { isStartSell } from './Common';
import { RrawResultContext } from '../Page/CheckDrawResult';
import { useBetSlip, AddM6SlipFunc } from '../../BetSlip/BetSlipHooks';
import CheckBox from '../../Common/ui-components/CheckBox';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';
import { glassboxCustomEvent } from '../../Common/utils/glassbox';
import { isPmuEoD, eodMessage } from '../../Common/GlobalFunc';

export const MyFavouritesContext = React.createContext(null);

export const getMyFavCookie = () => {
    const favs = document.cookie.split('; ').find((i) => i.includes('m6FavoritesCookie='));
    if (favs) {
        const myFavs = decodeURIComponent(favs.replace('m6FavoritesCookie=', '')).split('# ');
        const favList = [];
        myFavs.forEach((item) => {
            if (item) {
                const [favData, chance] = item.split('@');
                const favArr = favData.split('>');
                const numStr = favArr.length > 1 ? favArr[1] : favArr[0];
                const bankerStr = favArr.length > 1 ? favArr[0] : null;
                favList.push({
                    numList: numStr.split('+').map(x => x.trim()),
                    bankerList: bankerStr?.split('+').map(x => x.trim()),
                    chance
                });
            }
        });
        return favList;
    }
    return [];
};

const validateFavCookie = (favs) => {
    return /^[+@#>\d\s]*$/.test(favs);
};

export const setMyFavCookie = (fav, msg, del) => {
    const favMax = true;
    let oldFavs = document.cookie.split('; ').find((i) => i.includes('m6FavoritesCookie='));
    let favs = del ? '' : oldFavs ? decodeURIComponent(oldFavs).replace('m6FavoritesCookie=', '') : '';
    favs = validateFavCookie(favs) ? favs : '';
    const favMaxNum = 100;
    if (!del && (favs.split('# ').length - 1 >= favMaxNum || favs.split('# ').length - 1 + fav.length > favMaxNum)) {
        msg && alert(msg);
        return favMax;
    }
    fav.forEach((item) => {
        const ballStr = `${item.numList.join('+')}@${item.chance || 1}`;
        const bankerStr = item.bankerList?.length > 0 ? `${item.bankerList.join('+')}>` : '';

        favs += `# ${bankerStr}${ballStr}`;
    });
    const date = new Date();
    date.setTime(date.getTime() + 365 * 2 * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + date.toUTCString();
    let domainShare = '';
    const hostNameArr = window.location.host.split('.');
    if (hostNameArr.length > 1) {
        domainShare = ` domain=.${hostNameArr[1]}.${hostNameArr[2]};`;
    }
    document.cookie = `m6FavoritesCookie=${encodeURIComponent(favs)}; path=/;${domainShare} ${expires}`;
};

const selectOption = [
    { label: '---', value: '1' },
    { label: '5', value: '5' },
    { label: '10', value: '10' },
    { label: '20', value: '20' },
    { label: '30', value: '30' },
    { label: '---', value: 1 }
];

const MyFavourites = () => {
    const { t } = useTranslation();
    const context = useContext(MarksixContext);
    const pageName = context.pageName;
    const isSell = isStartSell(context.nextDraw);
    const [showTable, setShowTable] = useState();
    const { myFavourites, updateMyFavourites, curpageFavCheckArr, setCurpageFavCheckArr, favInitCheckState } =
        useContext(MyFavouritesContext);
    const [MyFavData, setMyFavData] = useState([]);
    const scSize = document.documentElement.clientWidth;
    const isSnowball = pageName.includes('SNOWBALL');
    const { addToBetSlip, betLines } = useBetSlip();

    useEffect(() => {
        setCurpageFavCheckArr([]);
    }, []);

    useEffect(() => {
        if (myFavourites) {
            let newData = [...MyFavData];
            myFavourites.forEach((item, index) => {
                if (!newData[index])
                    newData.push({
                        ...item,
                        checked: false,
                        multDraw: 1
                    });
            });
            if (favInitCheckState) {
                let num = 0;
                for (let i = 0; i < newData.length; i++) {
                    const item = newData[i];
                    if(item.checked){
                        item.checked = false;
                        num++;
                        if(num == favInitCheckState){
                            break;
                        }
                    }
                }
            }
            setMyFavData([...newData]);
            setCurpageFavCheckArr([...newData]);
        }
    }, [myFavourites, favInitCheckState]);

    useEffect(() => {
        if (MyFavData.length > 0 && !showTable) {
            setShowTable(true);
        }
    }, [MyFavData]);

    const handleClickCheckbox = (index) => {
        MyFavData[index].checked = !MyFavData[index].checked;
        setMyFavData([...MyFavData]);
        setCurpageFavCheckArr([...MyFavData]);
    };

    const moreOptions = (favData, index) => {
        const opts = [];
        for (let i = 5; i <= 30; i++) {
            opts.push(
                <Dropdown.Item
                    key={'down' + i}
                    onClick={() => onChangeMultDraw(i, index)}
                    active={favData.multDraw === i}
                    value={i}
                >
                    {i}
                </Dropdown.Item>
            );
        }
        return opts;
    };

    const onChangeMultDraw = (e, index) => {
        MyFavData[index].multDraw = e;
        setMyFavData([...MyFavData]);
        setCurpageFavCheckArr([...MyFavData]);
    };
    const deleteFav = (index) => {
        MyFavData.splice(index, 1);
        setMyFavData([...MyFavData]);
        setCurpageFavCheckArr([...MyFavData]);
        setMyFavCookie(MyFavData, '', true);
        updateMyFavourites();
    };

    const handleClickAddToSlip = () => {
        const initDefaultUnitBet = sessionStorage.getItem('Mk6DefaultUnitBetAmount')
            ? Number(sessionStorage.getItem('Mk6DefaultUnitBetAmount'))
            : 10;
        if (!isSell) {
            if (isPmuEoD() && eodMessage()?.length > 0) {
                return window.alert(eodMessage());
            } else {
                return window.alert(t('LB_M6_MSG_NOT_DIFINED'));
            }
        }

        let checkedList = MyFavData.filter((item) => item.checked);
        if (checkedList.length === 0) {
            alert(t('LB_M6_MSG_MYFAV_SELECTED_EMPTY'));
            return;
        }
        checkedList = checkedList.map((item) => {
            let bankerList = item.bankerList;
            if (!item.bankerList || item?.bankerList[0] === '') {
                bankerList = [];
            }
            return { ...item, unitBet: initDefaultUnitBet, bankerList, random: false };
        });
        const betList = AddM6SlipFunc(checkedList, context);
        const successAdd = addToBetSlip(betList)
        if(successAdd.acceptResult){
            let newData = [...MyFavData];
            if(successAdd.acceptType == 'part'){
                let num = 0;
                for (let i = 0; i < newData.length; i++) {
                    const item = newData[i];
                    if(item.checked){
                        item.checked = false;
                        num++;
                        if(num == successAdd.acceptBetSlipLength){
                            break;
                        }
                    }
                }
            } else {
                newData = newData.map((item) => {
                    return { ...item, checked: false };
                });
            }
            setMyFavData([...newData]);
            setCurpageFavCheckArr([...newData]);
        }


        glassboxCustomEvent('Add_to_Slip');
    };

    const tableProps = {
        MyFavData,
        showTable,
        scSize,
        handleClickCheckbox,
        t,
        isSnowball,
        onChangeMultDraw,
        moreOptions,
        deleteFav
    };

    return (
        <div className="my-favourites-table-container">
            <div className="my-favourites-header df">
                <div className="">
                    <ArrowIcon
                        className="hueRotate msFavouIcon"
                        onClick={() => setShowTable(!showTable)}
                        direction="down"
                        change={showTable}
                        changeDirection="up"
                        size={20}
                    />
                </div>
                <span>{t('LB_M6_SINGLE_TABLE_MYFAV')}</span>
            </div>
            <div className={`${showTable ? 'my-favourites-show' : 'my-favourites-hide'}`}>
                <FavTable {...tableProps} />
                <div className={`AddToSlip-container`}>
                    <button className="AddToSlip" title={t('LB_ADDTOSLIP')} onClick={handleClickAddToSlip}>
                        {t('LB_ADDTOSLIP')}
                    </button>
                </div>
            </div>
        </div>
    );
};

const FavTable = (props) => {
    const { MyFavData, showTable } = props;
    const table = [];

    return (
        <div className={`my-favourites-table ${showTable ? 'my-favourites-show' : 'my-favourites-hide'}`}>
            <FavTableHeader {...props} />
            {MyFavData.map((favItem, index) => {
                return (
                    <FavTableRow
                        {...props}
                        favData={favItem}
                        key={index}
                        index={index}
                        className={index % 2 ? 'sec' : ''}
                    />
                );
            })}
        </div>
    );
};

const FavTableHeader = ({ MyFavData, t, isSnowball }) => {
    return (
        <div className="table-header">
            <div className="table-cell table-checkbox-cell"></div>
            <div className="table-cell">{t('LB_M6_SINGLE_TABLE_NUMBER')}</div>
            <div className="table-cell">{t('LB_M6_SINGLE_TABLE_CHANCE')}</div>
            {isSnowball ? null : <div className="table-cell">{t('LB_M6_SINGLE_TABLE_MULTDRAW')}</div>}
            <div className="table-cell"></div>
        </div>
    );
};

const FavTableRow = (props) => {
    const {
        favData,
        index,
        className,
        scSize,
        handleClickCheckbox,
        t,
        onChangeMultDraw,
        moreOptions,
        isSnowball,
        deleteFav
    } = props;
    const imgSize = scSize > 1023 ? 'm' : 's';
    return (
        <div className={`table-row ${className}`}>
            <div className="table-cell table-cell-checkbox">
                <div>
                    <CheckBox
                        product="marksix"
                        type="checkbox"
                        checked={favData.checked}
                        onChange={() => handleClickCheckbox(index)}
                    />
                </div>
            </div>
            <div className="table-cell">
                <div className="df table-cell-ball-box">
                    {favData?.bankerList && (
                        <div className="df">
                            <div className="banker-label">{t('LB_M6_BANKERLABEL')}:</div>
                            <div className="df">
                                {favData?.bankerList?.map((i, ind) => {
                                    return (
                                        <div className="ball-img-box" key={ind}>
                                            <img
                                                className={`ballsize-${imgSize}`}
                                                src={
                                                    i &&
                                                    require(`../../../info/marksix/info/images/marksix-gold-${i}.svg`)
                                                }
                                                alt={i}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    <div className="df">
                        {favData?.bankerList && <div className="banker-label">{t('LB_M6_LEGLABEL')}:</div>}
                        <div className="df ball-list-container">
                            {favData.numList.map((i, ind) => {
                                return (
                                    <div className="ball-img-box" key={ind}>
                                        <img
                                            className={`ballsize-${imgSize}`}
                                            src={
                                                i &&
                                                require(`../../../info/marksix/info/images/marksix-${i}.svg`)
                                            }
                                            alt={i}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="table-cell table-cell-chance">{favData.chance}</div>
            {isSnowball ? null : (
                <div className="table-cell table-cell-multdraw">
                    <DropdownButton
                        title={
                            <>
                                <div>{favData.multDraw == 1 ? '---' : favData.multDraw}</div>
                                <ArrowIcon direction="down" size={24} />
                            </>
                        }
                        align="start"
                        value={favData.multDraw}
                    >
                        {sessionStorage.getItem('MK6MultipleDraw') == '1' && (
                            <>
                                {selectOption.map((item, i) => {
                                    return (
                                        <Dropdown.Item
                                            value={item.value}
                                            onClick={() => onChangeMultDraw(item.value, index)}
                                            active={favData.multDraw === item.value}
                                            key={'top' + i}
                                        >
                                            {item.label}
                                        </Dropdown.Item>
                                    );
                                })}
                                {moreOptions(favData, index)}{' '}
                            </>
                        )}
                    </DropdownButton>
                </div>
            )}
            <div className="table-cell table-cell-delete">
                <div className="df">
                    <div className="marksix-delete-icon" onClick={() => deleteFav(index)}></div>
                </div>
            </div>
        </div>
    );
};

export default MyFavourites;
